<template>
	<section class="news-details" :key="id">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/news">焦點消息</el-breadcrumb-item>
				<el-breadcrumb-item>{{_news.title}}</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<h1>
				<pre>{{_news.title}}</pre>
			</h1>
			<p>{{_news.writer || "作者資訊不明"}}
				<br>日期：{{_news.date | dateFilter}}</p>
			<hr>
			<br>
			<div v-html="_news.details" />
			<br>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		Carousel: () => import("@/components/carousel"),
		Thumb: () => import("@/components/thumb"),
	},
	props: ["id"],
	computed: {
		...mapState("news", ["_news"]),
		youtubeId() {
			const { youtube } = this._news;
			return youtube && querystring.parse(new URL(youtube).searchParams.toString())["v"] || ""
		}
	},
	watch: {
		id(val) {
			this.init();
		}
	},
	methods: {
		...mapActions("news", ["_getData"]),
		async init() {
			await this._getData(this.id);
		}
	},
	created() {
		this.init();
	}
}
</script>

<style lang="scss" scoped>
.news-details {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush2.png"),
		url("../../../assets/background/dot1.png"),
		url("../../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;
}
</style>